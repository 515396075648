import React from 'react'

const Team = props =>{

    return (
        <div>
            <h1>Team Page</h1>
        </div>
    )
}

export default Team