export const TOGGLE_DRAWER = "TOGGLE_DRAWER"


// core team form actions 
export const CORE_TEAM_NAME_CHANGE = "CORE_TEAM_NAME_CHANGE"
export const CORE_TEAM_POSITION_CHANGE = "CORE_TEAM_POSITION_CHANGE"
export const CORE_TEAM_EMAIL_CHANGE = "CORE_TEAM_EMAIL_CHANGE"
export const CORE_TEAM_GITHUB_CHANGE = "CORE_TEAM_GITHUB_CHANGE"
export const CORE_TEAM_LINKEDIN_CHANGE = "CORE_TEAM_LINKEDIN_CHANGE"
export const SUBMIT_CORE_TEAM_FORM = "SUBMIT_CORE_TEAM_FORM"

//community form actions 
export const COMMUNITY_NAME_CHANGE = "COMMUNITY_NAME_CHANGE"
export const COMMUNITY_EMAIL_CHANGE = "COMMUNITY_EMAIL_CHANGE"
export const COMMUNITY_COLLEGE_CHANGE = "COMMUNITY_COLLEGE_CHANGE"
export const COMMUNITY_TECH_CHANGE = "COMMUNITY_TECH_CHANGE"